button, .btn {
    border: none;
    border-radius: 2px;
    background: #2a912a;
    color: white;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    padding: 1px 35px;
    line-height: 22px;
    text-decoration: none;
}

