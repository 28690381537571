header {
    display: flex;
    justify-content: space-between;
    background: #6200EE;
    padding: 12px;
    color: white;
    font-family: Calibri;
}

header h1 {
    font-size: 20px;
    font-weight: normal;
}

.search {
    display: flex;
}

.search input {
    width: 60%;
}